import httpAxios from "@websanova/vue-auth/drivers/http/axios.1.x.js";
import routerVueRouter from "@websanova/vue-auth/dist/drivers/router/vue-router.2.x.js";
import { designerAPI } from "@/api/config";

let loginAPI = `${designerAPI}/login`;
let fetchAccAPI = `${designerAPI}/designer-profile`;

let authConfig = {
    auth: {
        request: function (req, token) {
            this.http.setHeaders.call(this, req, {
                Authorization: "Bearer " + token
            });
        },
        response: function (res) {
            return res.data.token;
        }
    },
    rolesKey: "role",
    tokenDefaultKey: "auth_tophillsdesigner_key",
    refreshTokenName: "auth_tophillsdesigner_refresh_key",
    stores: ["storage", "cookie"],
    http: httpAxios,
    router: routerVueRouter,
    staySignedIn: true,
    authRedirect: {
        path: "/login"
    },
    // forbiddenRedirect: {
    //   path: "/403"
    // },
    notFoundRedirect: {
        path: "/"
    },
    loginData: {
        url: loginAPI,
        method: "POST",
        redirect: "/",
        fetchUser: false,
        staySignedIn: false
    },
    logoutData: {
        redirect: "/login",
        makeRequest: false,
        url: "/api/logout",
        method: "POST"
    },
    fetchData: {
        url: fetchAccAPI,
        method: "GET",
        enabled: false
    },
    refreshData: {
        url: "/api/token/refresh",
        method: "POST",
        enabled: false,
        interval: 0
    }
};
export default authConfig;
