import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const auth = true;

const routes = [
    {
        path: "/forgot-password",
        name: "ForgotPassword",
        component: () => import("@/views/forgotPassword"),
        meta: {
            auth: false
        }
    },
    {
        path: "/login",
        name: "Login",
        component: () => import("@/views/index"),
        meta: {
            auth: false
        }
    },
    {
        path: "/",
        name: "Entry",
        component: () => import("@/views/entry"),
        redirect: { name: "ManageProfile" },
        meta: {
            auth: undefined
        },
        children: [
            /* =================================================================
             *                             Designs
             * =================================================================
             */
            {
                path: "/designs",
                name: "ManageFloorPlanDesigns",
                component: () => import("@/views/manageFloorPlanDesigns"),
                meta: {
                    auth: auth
                }
            },
            /* =================================================================
             *                             Projects
             * =================================================================
             */
            {
                path: "/projects",
                name: "Projects",
                component: () => import("@/views/projects"),
                meta: {
                    auth: auth
                }
            },
            {
                path: "/projects/:id",
                name: "ProjectsDetail",
                component: () => import("@/views/projects/detail/_detail"),
                meta: {
                    auth: auth
                }
            },
            {
                path: "/projects/:projectId/floor-plans/:id",
                name: "ProjectFloorPlansDetails",
                component: () =>
                    import("@/views/projects/floorPlans/detail/_detail"),
                meta: {
                    auth: auth
                }
            },
            /* =================================================================
             *                             Profile
             * =================================================================
             */
            {
                path: "/profile",
                name: "ManageProfile",
                component: () => import("@/views/manageProfile"),
                meta: {
                    auth: auth
                }
            }
        ]
    },
    /* =========================================================================
     *                              Miscellaneous
     * =========================================================================
     */
    {
        path: "/maintenance",
        name: "Maintenance",
        component: () => import("@/views/maintenance"),
        meta: {
            auth: undefined
        }
    },
    {
        path: "/*",
        name: "NotFound",
        component: () => import("@/views/404/NotFound"),
        meta: {
            auth: undefined
        }
    }
];

const router = new VueRouter({
    mode: "history",
    routes
});

Vue.router = router;

router.beforeEach((to, from, next) => {
    let isMaintenance = process.env.VUE_APP_MAINTENANCE_MODE;
    if (isMaintenance == 1) {
        if (to.name == "Maintenance") {
            next();
            return;
        }
        next({ name: "Maintenance" });
        return;
    }
    next();
});

export default router;
